import React from 'react'

export const Dain = () => {

    const data = [
        {
          name : "의성안전체험관",
          address : "다인면 자미로",
          desc : "의성군 다인면 자미로에 위치한 의성안전체험관 입니다. 경상북도교육청 의성안전체험관은 학생 스스로가 평상시 지속적이고 반복적인 안전교육을 통해 재난대응 요령을 생활화∙습관화하고 안전역량 및 안전의식을 충분히 갖춘 어른으로 성장하도록 돕기 위해 건립되었습니다. 저희 경상북도교육청 의성안전체험관은 학교 안전교육 7대 표준안을 바탕으로 총 5개관 18개실로 구성하여, 학생들이 각종 재난 및 위험에 대처할 수 있는 다양한 체험을 할수 있도록 체계적이고 전문적인 안전교육 프로그램을 운영하는 종합안전체험관입니다. 또한 시대에 따라 변화하는 각종 재난에 대비하여 위험요소를 사전에 예측하고 자신과 타인의 생명을 존중하며 안전하고 건강한 생활을 영위할 수 있는 능력을 배양하는데 중심 역할을 할 것입니다. 경상북도교육청 의성안전체험관의 전 직원들은 모든 학생들이 안전하고 행복한 생활을 위하여 소중한 경험과 지식을 체험으로 습득하게 하고, 만일의 사고나 재해, 재난 등에 대처능력을 갖출 수 있도록 좋은 체험장비들이 구비되어 있습니다. 방문을 했을때 여러 자연재해 재난에 대한 장비들이 매우 구비가 잘되어 있었습니다.",
          img1 : "./location/protect1.png",
          img2 : "./location/protect2.png"
        }
    ]
    return (
        <div>
            <h2># 다인면</h2>
            <ul>
        {data.map(item => {
          return (
            <li className="tourList">
              <div>
              {item.img1 && 1 ? <img src={item.img1} alt={item.name} /> : <></>}
              {item.img2 && 1 ? <img src={item.img2} alt={item.name} /> : <></>}
              </div>
              <h2># {item.name}</h2>
              <h3>{item.address}</h3>
              <p>{item.desc}</p>
            </li>
          )
        })}
      </ul>
        </div>
    )
}