import React from "react";
import { Layout } from "../components/layout";

export const MiniGame = () => {
  return (
    <Layout>
      <p>asdf</p>
    </Layout>
  );
};
