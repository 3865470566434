import React from 'react'
export const Jeomgok = () => {

  const data = [
    {
      name : "사촌리 가로숲",
      address : "점곡면 사촌리",
      desc : "의성군 점곡면 사촌리에 위치한 사촌리 가로숲입니다. 의성 사촌리 가로숲은 고려말 안동 김씨 입향조(入鄕祖)인 김자첨이 안동으로부터 이곳 사촌으로 이사를 와서 마을 서쪽의 평지에서 불어오는 바람을 막기 위해 만든 방풍림이라고 전해진다. 사촌에서는 이 숲을 서림(西林)이라고 부르고 있다. 현재 이 숲에는 나이가 300∼600년 정도되는 상수리나무, 느티나무, 팽나무 등 10여 종, 500여 그루의 나무가 자라고 있다. 산책하기 좋고 마음이 무겁거나 고민이 있을때 걸으면 마음이 잠깐은 편안해 지는 것 같다.",
      img1 : "./location/sachonri1.png",
      img2 : "./location/sachonri2.png"
    },
    {
      name : "사촌전통마을",
      address : "점곡면 사촌리",
      desc : "의성군 점곡면 사촌리에 위치한 사촌전통마을 입니다. 사촌 마을의 이름은 중국의 사진리을 본 따서 지은 이름이라고  합니다. 마을에 내려오는 전설에는 이 마을에 3명의 정승이 태어 난다고 하며, 신라시대 한 명, 조선시대 류성룡 그리고 한사람이 더 태어날 것이어서 마을 어른들은 출가한 여인들이 친정으로 돌아와 애를 낳는 것을 원치 않는 풍습이 있다고 합니다. 사촌마을을 처음 들어가면 조그만 연못이 있는데 연꽃이 가득 피어 있습니다.  사촌마을 안쪽에 가장오래된 만취당이 있습니다. 그 만취당에 있는 안내판을 보고 가면 사촌마을자료 전시관이 나오는데 사촌마을의 유래와 민속, 출신 인물 등에 대한 자료가 전시되어 있어 마을에 대한 이해를 돕기 위해 반드시 들려야 한다.",
      img1 : "./location/sachonma1.png",
      img2 : "./location/sachonma2.png"
    },
    {
      name : "사촌은행나무숲",
      address : "점곡면 중리들길",
      desc : "의성군 점곡면 중리들길에 위치한 사촌은행나무숲 입니다. 사촌은행나무숲에는 비하인드 스토리가 있습니다. 사장님께서 10여년간 혼자서 가꿔온 곳인데 입구에는 조형물이 엄청 많습니다. 그 조형물들은 사장님께서 손수 혼자서 다 만드신 것이라고 합니다. 아직 완성이 덜 됬지만 2023년 안에 캠핑장도 운영해볼 계획이라고 하셨습니다.",
      img1 : "./location/sachontree1.png"
    }
  ]

  return (
    <div>
      <h2># 점곡면</h2>
      <ul>
        {data.map(item => {
          return (
            <li className="tourList">
              <div>
              {item.img1 && 1 ? <img src={item.img1} alt={item.name} /> : <></>}
              {item.img2 && 1 ? <img src={item.img2} alt={item.name} /> : <></>}
              </div>
              <h2># {item.name}</h2>
              <h3>{item.address}</h3>
              <p>{item.desc}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}