import React from 'react'

export const Geumseong = () => {

  const data = [
    {
      name : "조문국 박물관",
      address : "금성면 초전리",
      desc : "의성군 금성면 초전리에 위치한 조문국 박물관으로, 박물관 앞에는 의성 지역의 대표적 문화재인 국보 제 77호인 의성 탑리리 5층 석탑의 실물 모형으로 세우고,내부에는 500년 뒤인2512년에 개봉 예정인 타임캡슐을 보관한 상징 조형물을 세워 두었습니다. 조문국박물관에서는 전국에 흩어진 조문국의 유물들을 되찾아 한 자리에 모으기  위한 노력을 계속 해왔습니다. 그 결과, 2016년에 국가귀속 유물 보관관리 기관으로 지정되어, 한국의 소중한 유물을 보관 · 관리하는 박물관으로 거듭났습니다. 이에 따라 2017년에는 기관 지정 이후 최대로 많은 수량의 유물을 인수하여 사람들에게 전시를 통해 개방하게 되었습니다.",
      img1 : "./location/Jomunguk museum1.png",
      img2 : "./location/Jomunguk museum2.png"
    },
    {
      name : "조문국 사적지",
      address : "금성면 동부로",
      desc : "의성군 금성면 동부로에 위치한 의성 조문국 사적지 입니다. 조문국 사적지 입구부터 경덕왕릉과 고분 전시관을 비롯하여 고분군 일대 모두 돌아보는 데에는 약 20분 정도 걸리는데요 조문국 사적지를 둘러보기 앞서, 전망대에 올라가 금성산과 함께 펼쳐진 고분들이 만들어낸 멋진 풍경을 먼저 보고 가시는 것을 추천해드립니다. 또한, 늦은 봄에서 초여름 사이에 방문하시면 고분군 일대의 작약꽃이 만개한 모습도 볼 수 있습니다. 40여 기의 중대형 고분들을 따라 걷다 보면, 잘 알려지지 않았던 조문국의 비밀이 한꺼풀씩 벗겨져가는 느낌이 듭니다. 오늘날, <삼국사기>를 제외하고는 조문국에 대한 이야기를 거의 찾아볼 수 없는데요, 조문국 사적지에서 출토된 유물과 무덤 양식등을 통해서 우리의 소중한 역사를 지금 이 순간에도 찾아가고 있는 중이라고 합니다.",
      img1 : "./location/Jomunguk sa1.png"
    },
    {
      name : "탑리마을",
      address : "금성면 탑리길",
      desc : "경북 의성군 금성면 탑리길에 위치한 의성 탑리마을은 마치 영화 세트장처럼 시간이 멈춘 듯한마을로, 그 속에서 살아가는 마을 주민들의 따뜻함까지 물씬 느껴지는 곳입니다. 탑리역에서부터 천천히 돌아보며 마을 곳곳의 레트로함을 곱씹어 보기에 좋으며, 걷는 것만으로도 힐링이 되는 것 같습니다. 탑리마을은 관광지화가 된 곳이 아니기에 이리저리 걷다 보면 마치 보물 찾기를 하듯이 레트로한 간판과 건물들을 찾아보는 재미를 느낄 수 있습니다. 옛날 배경의 영화 드라마에서만 볼 수 있었던 세월이 느껴지는 글씨체의 간판은 좋은 포토존이 되기도 합니다.",
      img1 : "./location/TOPRI1.png",
      img2 : "./location/TOPRI2.png"
    },
    {
      name : "산운마을",
      address : "금성면 산운리",
      desc : "금성산 자락 금성면 산운리에 위차한 의성 산운마을 입니다. 점곡면에 위치한 또다른 한옥마을인 사촌마을 보다는 더 한적하고 소담스러운 마을입니다. 입구 앞에 있는 학록정사를 구경하고 마을로 들어가면 그야말로 시골 농촌의 풍경이 눈에 들어옵니다. 그리고 그 뒤쪽으로 금성산 자락이 펼쳐지는데 도시생활에 지쳐있어 그런지 농촌풍경을 바라보며 걷는 것만으로도 힐링이 됩니다. 그렇게 길을 따라 걷다보면 의성 소우당이라는 건물이 나옵니다. 소우당을 구경하고 별채를 본 뒤 학록정사 뒤쪽길을 따라 언덕을 오르면 아름다운 풍경이 나옵니다.",
      img1 : "./location/sanma1.png",
      img2 : "./location/sanma2.png"
    },
    {
      name : "산운생태공원",
      address : "금성면 산운리",
      desc : "의성군 금성면 산운리에는 산운생태공원이 있습니다. 공원에서 한반도 최초의 사화산이라고 불리는 금성산이 바로 보입니다. 금성산과 그 옆에 위치한 비봉산이 감싸고 있습니다. 산운생태공원은 처음부터 공원으로 조성된 것이 아닙니다. 무려 1925년에 개교한 산운국민학교로 사용되다가 1995년에 폐교되었고 그렇게 한동안 방치되었습니다. 그러다가 2000년 이후에 지금의 산운생태공원으로 모습을 바뀌어 왔습니다. 그래서 과거 학교라는 사실을 알 수 있는 흔적도 많이 남아 있습니다. 이순신 장군 동상이 있으며, 공부하는 어린이라는 주제의 동상도 어김없이 자리 잡고 있습니다. 너무 크지도 작지 않은 2층의 학교 건물은 현재 공룡과 지구과학에 한 내용을 다루는 전시실로 사용됩니다.​ 또한 많은 사람들이 알고 있는 1박2일을 촬영한 곳이기도 하고 그 이후 거대한 그네도 설치되었습니다. 바로 옆에는 대감마을이라고도 불리는 산운마을이 있는데, 이곳은 영천 이 씨의 집성촌이기도 합니다. 현제까지 40여 동의 전통가옥이 잘 보존되어 있습니다.",
    img1 : "./location/sanpark2.png"
    },
     {
      name : "금마늘다목적센터",
      address : "금성면 탑운길",
      desc : "의성군 금성면 탑운길에 위치한 금마늘다목전센터 입니다. 지역주민의 문화활동과 도농교류를 위한 숙박, 교육장, 체험프로그램을 갖춘 곳입니다. 특히 건강을 주제로 내몸 바로 알기, 건강식단체험하기, 건강마사지 및 운동법등을 함께 체험할 수 있습니다. 조문국박물관과 의성제오리공룡발자국을 5분거리 내로 방문할 수 있어 다목적센터에서 숙박과 농촌음식을 체험하면서 연계할 수 있습니다.",
      img1 : "./location/goldgal1.png",
      img2 : "./location/goldgal2.png"
    }, 
     {
      name : "의성바람꽃농원",
      address : "금성면 운곡리",
      desc : "의성군 금성면 윤곡리에 위치한 의성바람꽃농원 입니다. 바람꽃농원은 2000평대 규모의 농원으로 농촌체험이 가능합니다. 그리고 내 몸, 건강을 알게되는 장소로 매년 많은 분들이 다녀갑니다. 그리고 다른 곳에서는 경험할 수 없는 자유힐링 체험과, 자연섭리 체험, 내 몸 알기체험 등 프로그램의 특별함을 인정받아 2019년에 경상북도로부터 농촌교육농장으로 지정받았습니다.",
      img1 : "./location/flowerpark1.png",
      img2 : "./location/flowerpark2.png" 
     }
  ]

  return (
    <div>
      <h2># 금성면</h2>
      {/* 복붙 영역 ▽*/}
      <ul>
        {data.map(item => {
          return (
            <li className="tourList">
              <div>
              {item.img1 && 1 ? <img src={item.img1} alt={item.name} /> : <></>}
              {item.img2 && 1 ? <img src={item.img2} alt={item.name} /> : <></>}
              </div>
              <h2># {item.name}</h2>
              <h3>{item.address}</h3>
              <p>{item.desc}</p>
            </li>
          )
        })}
      </ul>
      {/* 복붙 영역 △*/}
    </div>
  )
}