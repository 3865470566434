import React from 'react'

export const Uiseong = () => {

    const data = [
        {
          name : "구봉공원",
          address : "금성면 초전리",
          desc : "의성군 의성읍 도서리에 위치한 구봉공원 입니다. 구봉공원은 의성의 관문 ‘숭의문’부터 시작해서 구봉산 입구까지 길게 이어진 공원입니다. 숭의문에서 시작하면 남대천변을 전망할 수 있는 정자가 있습니다.​정자에서 바라보는 남대천변이 가히 일품입니다. 남대천을 구경하며 꽃길을 걸으면 마늘과 관련된 조형물들이 나오기 시작합니다. 의성은 마늘이 유명합니다. 마늘과 관련된 단군신화에서나온 호랑이와 곰을 형상화한 모형이 나옵니다. 구봉공원은 한쪽 부분만 꽃길이 있는 것이 아닌 산자락에도 데크가 별도로 조성되어 거닐 수 있도록 해놓았습니다. 구봉공원에는 각종 체육시설 또한 구비되어 있습니다. 남녀노소 누구나 다양한 종목의 운동을 할 수 있도록 준비되어 있습니다. 그리고 꽃길의 꽃뿐만 아니라 다양한 수종의 꽃을 구경할 수 있습니다. 구봉공원에는 중간중간 쉴 수 있도록 곳곳에 정자가 설치되어 있고 마늘 모형이상당히 많습니다.",
          img1 : "./location/9bongpark1.png",
          img2 : "./location/9bongpark2.png"
        },
        {
          name : "제월아트체험센터",
          address : "의성읍 의성안길로",
          desc : "의성군 의성읍 의성길안로에 위치한 제월아트체험센터 입니다. 제월아트체험센터는 문화예술이 가깝게 느껴질 수 있도록 이해와 공감을 키우는 문화예술공간을 운영 하며, 지역의 문화와 주민을 이어주는 플랫폼 역할을 통해 하나의 공동체로 조성 할 수 있도록 도움을 주는 기업 입니다. 뿐만 아니라 취약계층일자리 창출 및 문화예술서비스 등 사회서비스 제공으로 지역사회에 환원함으로써 지역민의 삶의 질 향상과 더불어 지역의 꿈나무들의 꿈을 함께 키워나가고자 하는 센터입니다. 시즌에 따라 체험할 수 있는 컨텐츠가 다르기 때문에 온 적이 있어도 들려보는 것도 좋은 경험이 될 것 같습니다.",
          img1 : "./location/moonart1.png"
        },
        {
          name : "의성마늘테마파크",
          address : "의성읍 경북대로",
          desc : "의성군 의성읍 경북대로에 위치한 의성마늘테마파크 입니다. 의성마늘체험관은 대한민국 국가대표 마늘인 “의성마늘”을 이용하여 맛있는 요리를 직접 만들어 시식해 볼 수 있는 열린 체험의 공간을 제공합니다. 의성마늘 체험관은 의성마늘의 생산을 확대하고 제품의 고급화를 통해 의성마늘을 전국적인 먹거리로 자리매김함은 물론 마늘과 더불어 의성 농·특산물의 고부가 가치증대와 도시민 체험 관광객 확대로 의성군 이미지 제고와 지역 6차 산업 활성화로 직·간접적으로 지역경제 발전에 기여할 수 있도록 노력하고 있습니다. 맑고 깨끗한 의성마늘체험관에 가족, 연인, 친구와 함께 오셔서 체험의 즐거움을 만끽하시고 소중한 추억도 만들어 보세요.",
          img1 : "./location/temapark1.png",
          img2 : "./location/temapark2.png"
        },
        {
          name : "의성컬링센터",
          address : "의성읍 충효로",
          desc : "경상북도 의성군 의성읍 충효로에 위치한 의성컬링센터입니다. 2018 평창동계올림픽에서 컬링 신드롬을 일으킨 “팀 킴”(경북체육회 여자컬링팀)을 육성해낸 곳입니다. 엘리트 컬링 전문 훈련장소로 활용 하며, 일반인 동호회, 체험도 가능합니다. 2019년 부터 의성군 직영으로 운영 하고 있으며, 국내에서 가장 저렴한 요금으로 컬링을 즐길수 있으며, 장비대여, 시설대관, 강습까지 이루어지고 있습니다. 컬링을 체험해보고 싶으신 분들은 가보시면 좋은 경험이 될 것 같습니다.",
          img1 : "./location/curling center1.png",
          img2 : "./location/curling center2.png"
        },
        {
          name : "의성 문소루",
          address : "의성읍 원당리",
          desc : "의성군 의성읍 경북대로에 위치한 문소루 입니다. 문소루는 신라 경덕왕16년(757)부터 문소라 이름한 것으로 이 루를 창건한 연대는고려 중엽으로 추정된다. 공민왕 때 현령 이원제가 중건하였으나 효종8년(1675) 정유화재로 소실된 것을 숙종20년(1694)에 현령 황응일이 두번째 증건하였다.  옛 터는 관아(읍 중심부) 서북쪽에 위치였고, 돌 기둥 위 아홉칸 규모의 나무로 지어진 누상에는 학사 김지대, 포은 정몽주, 상촌 김자수 등 제선생의 시판(제영)과 화산 이지원 선생의 문소루중건기문이 있었는데, 안타깝게도 6.25 전란으로 루와 함께 소실되었다.",
        img1 : "./location/doorsoru1.png"
        }
      ]
    return (
        <div>
            <h2># 의성읍</h2>
            <ul>
        {data.map(item => {
          return (
            <li className="tourList">
              <div>
              {item.img1 && 1 ? <img src={item.img1} alt={item.name} /> : <></>}
              {item.img2 && 1 ? <img src={item.img2} alt={item.name} /> : <></>}
              </div>
              <h2># {item.name}</h2>
              <h3>{item.address}</h3>
              <p>{item.desc}</p>
            </li>
          )
        })}
      </ul>
        </div>
    )
}